<template>
    <div id="luckDraw">
        <div class="top">
            <div class="left">
                <h4>中 奖 名 单</h4>
                <div class="box">
                    <div class="small_title">
                        <span>用户名</span>
                        <span>获得奖品</span>
                    </div>
                    <div class="scroll_box">
                        <ul :class="{scroll: moblesAnimate}">
                            <li v-for="(item, index) in mobles" :key="index">
                                <span>{{item.moble}}</span>
                                <span>获得{{item.name}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="right">
                <h4>礼品清单</h4>
                <div class="box">
                    <div class="right_scroll_box">
                        <van-swipe class="gift_swipe" width="134" autoplay="2000" :show-indicators="false">
                            <van-swipe-item v-for="(item, index) in activeGift" :key="index">
                                <div class="swipe_box">
                                    <img :src="item.giftImg"/>
                                </div>
                            </van-swipe-item>
                        </van-swipe>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="bottom">
            <div class="box"></div>
            <img class="title" src="../../images/redcardtitle.png" @click="drawGiftShow = true"/>
        </div>
        <van-popup v-model="drawGiftShow" class="drawGiftShow" :overlay="false">
            <div class="bag">
                <div v-for="item in 2" :key="item">
                    <img v-for="res in 3" :key="item+res" @click="draw" src="../../images/blessingbag.png"/>
                </div>
            </div>
        </van-popup>
        <van-popup v-model="giftShow" class="giftShow" overlay-class="overGiftShow">
            <template>
                <img class="horn_l" :src="`${horn_l}?${time}`">
                <img class="horn_r" :src="`${horn_r}?${time}`">
            </template>
            <template>
                <div class="show_top">
                    <ul :class="{scroll: moblesAnimate}">
                        <li v-for="(item, index) in mobles" :key="index">
                            <span>{{item.moble}}</span>
                            <span> -- 获得{{item.name}}</span>
                        </li>
                    </ul>
                </div>
                <div class="show_content">
                    <div class="left_qrcode">
                        <img :src="activeGiftQrcode"/>
                    </div>
                    <div class="right_qrcode">
                        <img :src="drawGift.giftImg" />
                        <p>{{drawGift.giftName}}</p>
                    </div>
                    <p>请扫码领取</p>
                </div>
                <div class="show_bottom">
                    <i class="iconfont icon-add-sy" @click="giftShow = false; isDraw = false"></i>
                </div>
            </template>
        </van-popup>
        <audio src="../../images/winningtheprize.mp3" ref="winningtheprize" class="myAudio"></audio>
    </div>
</template>

<script>
import {getgroupgift, getgiftqrcode} from '@/api/redCard';
export default {
    data () {
        return {
            token: '',
            id: '',
            mobles: [], //手机号
            moblesAnimate: false, //中奖列表滚动
            giftAnimate: false, //礼品列表滚动
            activeGift: [], //礼品
            probability: [],//抽奖概率
            isDraw: false, //是否开始抽奖
            drawGift: {}, //抽中礼品
            drawGiftShow: false, //抽奖界面
            giftShow: false, //中奖界面
            activeGiftQrcode: '',
            hornShow: false,
            time: '',
            horn_l: require(`../../images/horn_l.gif`),
            horn_r: require(`../../images/horn_r.gif`),
        }
    },
    mounted () {
        const {token, id} = this.$route.query;
        token && (this.token = token);
        id && (this.id = id);
        this.groupGift(id);
        setInterval(this.moblesScroll, 1000);
    },
    methods: {
        /* 随机手机号 */
        randomMoble () {
            var prefixArray = new Array("130", "131", "132", "133", "135", "137", "138", "170", "187", "189");
            var giftName = [];
            this.activeGift.map(data => {
                giftName.push(data.giftName);
            });
            for (var i=0; i<30; i++) {
                var prefix = prefixArray[parseInt(10*Math.random())];
                for (var j = 0; j < 8; j++) {
                    prefix = prefix + Math.floor(Math.random() * 10);
                }
                var moble = {
                    moble: `${prefix.substring(0,3)}****${prefix.substring(7)}`,
                    name: giftName[parseInt(giftName.length*Math.random())]
                };
                this.mobles.push(moble);
            }
        },

        /* 中奖列表滚动 */
        moblesScroll () {
            this.moblesAnimate = true;
            setTimeout(() => {
                this.mobles.push(this.mobles[0]);
                this.mobles.shift();
                this.moblesAnimate = false;
            },500)
        },

        /* 获取指定礼品分组的礼品清单 */
        async groupGift (groupId) {
            const {token, $toast} = this;
            let res = await getgroupgift({token, groupId});
            console.log(res);
            const {status, data, message} = res;
            if (status == 0) {
                this.activeGift = data.data;
                this.activeGift.map(data => {
                    for (var i=0; i<data.probability; i++) {
                        this.probability.push(data.id);
                    }
                });
                this.probability.sort(function() {
                    return (0.5-Math.random());
                });
                this.randomMoble();
            } else {
                $toast(message);
            }
        },

        /* 抽奖 */
        draw () {
            const {activeGift, isDraw, probability} = this;
            if (!isDraw) {
                var num = parseInt(probability.length*Math.random());
                activeGift.map(data => {
                    if (data.id == probability[num]) {
                        this.drawGift = data;
                    }
                });
                this.giftQrcode();
                this.isDraw = true;
                setTimeout(() => { this.$refs.winningtheprize.play() }, 100)
            }
        },

        /* 获取营销活动礼品的二维码 */
        async giftQrcode () {
            const {token, drawGift, $toast} = this;
            $toast.loading();
            let res = await getgiftqrcode({token, productId: drawGift.mallProductId});
            const {status, data, message} = res;
            if (status == 0) {
                $toast.clear();
                this.drawGiftShow = false;
                this.giftShow = true;
                this.activeGiftQrcode = data.qrcode;
                this.time = new Date().getTime();
                this.hornShow = true;
                setTimeout(() => {this.hornShow = false}, 1500);
            } else {
                $toast(message);
            }
        }
    }
}
</script>

<style lang="less" src="@/style/drawRedEnvelopes/luckDraw.less">

</style>